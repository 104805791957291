import axios from "axios";
import authHeader from "../../api/services/token.interceptor";
const API_URL= process.env.REACT_APP_API_URL;

export const fetchTalkHistory = async (chat_id) =>
    await axios.get(`${API_URL}/history-talk/${chat_id}`, {
        headers: authHeader(),
    })

export const fetchChatHistory =async () =>
    await axios.get(`${API_URL}/history-chat`, {
        headers: authHeader(),
    })

export const fetchCreditCount = async () =>
    await axios.get(`${API_URL}/get/premium`, {
        headers: authHeader(),
    })

