import styled from "styled-components";
export const MenuBackdropWrapper = styled.div`
  transition-property: all;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  transition-duration: .15s;
  opacity: .2;
  background-color: rgb(36 36 36);
  width: 100vw;
  height: 100vh;
  display: none;
  z-index: 10;
  position: fixed;

  @media all and (max-width: 975px) {
    display: ${props =>  (props?.isMenuOpen ? 'block' : 'none')};
  }
`;