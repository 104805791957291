import React, { lazy, Suspense } from "react";
import { InLineLoader } from "./components/InlineLoader/InlineLoader";
import {Route,Routes} from "react-router-dom";
import * as navigations from "./utils/navigation";
import Layout from "./layouts/Layout";

const Chat = lazy(() => import("./pages/Chat/Chat"));
const ChatSessions = lazy(() => import("./pages/ChatSessions/ChatSessions"));
const Payments = lazy(() => import("./pages/Payments/Payments"));
const CorporateProcess = lazy(() => import("./pages/CorporateProcess/CorporateProcess"));
const Search = lazy(() => import("./pages/Search/Search"));
const Settings = lazy(() => import("./pages/Settings/Settings"));

const LfRoutes = () => {
  return (
        <Layout>
            <Suspense fallback={<InLineLoader />}>
                <Routes>
                    <Route exact={true} path={navigations.CHAT} element={<Chat/>}/>
                    <Route exact={true} path={navigations.CHAT_SESSIONS} element={<ChatSessions/>}/>
                    <Route exact={true} path={navigations.SEARCH} element={<Search/>}/>
                    <Route exact={true} path={navigations.PAYMENTS} element={<Payments/>}/>
                    <Route exact={true} path={navigations.CORPORATE_PROCESS} element={<CorporateProcess/>}/>
                    <Route exact={true} path={navigations.SETTINGS} element={<Settings/>}/>
                </Routes>
            </Suspense>
        </Layout>

  );
};

export default LfRoutes;
