import styled from 'styled-components';

export const SidebarWrapper = styled.div`
  @media all and (max-width: 975px) {
    display: ${props =>  (props?.isMenuOpen ? 'inline-block' : 'none')};
  }
  position: fixed;
  transition-property: all;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  transition-duration: .15s;

  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));

  border-width: 1px;
  border-radius: 0.75rem;
  height: 98%;
  width: 4.5rem;
  z-index: 20;
  left: 0;
  top: 0;
  box-sizing: border-box;
  border-style: solid;
  border-color: #e5e7eb;
  margin: 0.5rem;
  
  .sidebar-content{
    gap: 0.5rem;
    align-items: center;
    flex-direction: column;
    height: 100%;
    display: flex;
    position: relative;
    
    .sidebar-btn-active{
      background-color: #069!important;
    }
    
    .sidebar-btn{
      cursor: pointer;
      transition-property: all;
      transition-timing-function: cubic-bezier(.4,0,.2,1);
      transition-duration: .15s;
      padding: 1rem;
      border-radius: 1rem;
      -webkit-appearance: button;
      background-color: transparent;
      background-image: none;
      text-transform: none;
      box-sizing: border-box;
      border-width: 0;
      border-style: solid;
      border-color: #e5e7eb;

      font-family: inherit;
      font-feature-settings: inherit;
      font-variation-settings: inherit;
      font-size: 100%;
      font-weight: inherit;
      line-height: inherit;
      color: inherit;

      
      &:hover {
        --tw-bg-opacity: 1;
        background-color: rgb(242 242 242 / var(--tw-bg-opacity));
      }
    }
   .sidebar-btn-image{
     width: 1.5rem;
     max-width: 100%;
     height: auto;
     display: block;
     vertical-align: middle;
   }
    .sidebar-hr{
      --tw-border-opacity: 1;
      border-color: rgb(163 163 163 / var(--tw-border-opacity));
      border-width: 1px;
      width: 2.25rem;
      //height: 0;
      color: inherit;
    }
    .sidebar-grow{
      flex-grow: 1;
    }
  }
`;


export const BoxPorgotPassword = styled.div`
     margin-top: 25px;
     text-align: center;
     a{
       font-size: 16px;
       color: #006699;
     }
  `;

export const Input = styled.input`
  width: 100%;
  height: 48px;
  border-radius: 6px;
  background-color: #eee;
  border: 1px solid #eee;
  outline: none;
  font-size: 15px;
  font-weight: 400;
  color: #0D1136;
  line-height: 19px;
  padding: 0 18px;
  box-sizing: border-box;
  transition: border-color 0.25s ease;
  margin-bottom: 10px;
  margin-top:8px;

  &:hover,
  &:focus {
    outline: 0;
  }

  &:focus {
    border-color: #006699;
  }

  &::placeholder {
    color: #77798c;
    font-size: 14px;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &.disabled {
    .inner-wrap {
      cursor: not-allowed;
      opacity: 0.6;
    }
  }
`;

export const StyledError = styled.div`
  color: red;
  padding-bottom: 10px;
  margin-top: -5px;
`;

export const LoginError = styled.div`
      background:#FCAEA0;
      padding:4px;
      border-radius: 10px;
      p{
        font-size: 14px;
        text-align: center;
        line-height: 1.4;
      }
    `;
