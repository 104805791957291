import * as Actions from "./constants";

export const initialState = {
    data: null,
    isOpenStripePayment:false,
    isNavbarOpen:true,
}

const modalReducer = (state = initialState, action) => {
    switch (action.type) {

        case  Actions.SHOW_STRIPE_PAYMENT_MODAL:
            return {
                ...state,
                isOpenStripePayment:true,
                data:action.payload,
                isNavbarOpen:false
            };

        case Actions.HIDE_MODAL:
            return initialState;

        default:
            return state;
    }
};

export default modalReducer;
