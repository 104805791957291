import styled from "styled-components";
export const ResponsiveBtnWrapper = styled.button`
  box-shadow:  0 0 #0000, 0 0 #0000,0 4px 6px -1px rgb(0 0 0 / .1), 0 2px 4px -2px rgb(0 0 0 / .1);
  padding: 0.25rem 0.5rem;
  background-color: rgb(242 242 242);
  border-color: rgb(222 216 207);
  border-width: 1px;
  border-radius: 10px;
  aspect-ratio: 1 / 1;
  display: none;
  margin: 0.5rem;
  z-index: 10;
  position: fixed;
  cursor: pointer;
  text-transform: none;
  @media all and (max-width: 975px) {
    display: block;
  }
  .responsive-btn-image{
    height: 1.5rem;
    max-width: 100%;
    display: block;
    vertical-align: middle;
    overflow-clip-margin: content-box;
    overflow: clip;
  }
`;