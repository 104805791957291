import React from "react";
import {SidebarWrapper} from "./SideBar.style";
import {Link, useLocation} from "react-router-dom";
import * as navigations from "../../utils/navigation";
import Swal from "sweetalert2";

const Sidebar = (props) => {
    const {isMenuOpen}=props;
    let location = useLocation();
    const token=localStorage.getItem('token');
    const chat_id=localStorage.getItem('chat_id');
    const getNavLinkClass = (path) => {
        return location.pathname === path
            ? "sidebar-btn-active"
            : path.split("/")[1] === location.pathname.split("/")[1]
                ? "sidebar-btn-active"
                : "";
    };

    const handleLogout=()=>{
        Swal.fire({
            title: 'Deconnexion Alphy',
            html: `Êtes-vous sûr de vouloir vous déconnecter de votre compte?`,
            icon: 'info',
            confirmButtonColor: '#006699',
            confirmButtonText: 'Se déconnecter'
        }).then((result) => {
            if (result.isConfirmed) {
                localStorage.removeItem('token');
                localStorage.removeItem('chat_id');
                localStorage.removeItem('user_id');
                window.location.reload();
            }
        });
    }
    return (
       <>
           <SidebarWrapper isMenuOpen={isMenuOpen}>
               <div className="sidebar-content">
                   <Link to={navigations.CHAT} className={`sidebar-btn`}>
                       <img className="sidebar-btn-image" src="https://www.legafrik.com/hubfs/Legafrik_CI/logo%20Legafrik.png" alt="Legafrik"/>
                   </Link>
                   <hr className="sidebar-hr"/>
                   <Link to={navigations.CHAT} className={`sidebar-btn ${getNavLinkClass(navigations.CHAT || navigations.CHAT_SESSIONS)}`}>
                       <img className="sidebar-btn-image"  src={getNavLinkClass(navigations.CHAT)===""?"/assets/images/menu/blue-chat.svg":"/assets/images/menu/white-chat.svg"} alt="ChatSessions"/>
                   </Link>
                   <Link to={navigations.SEARCH} className={`sidebar-btn ${getNavLinkClass(navigations.SEARCH)}`}>
                       <img className="sidebar-btn-image" src={getNavLinkClass(navigations.SEARCH)===""?"/assets/images/menu/blue-search.svg":"/assets/images/menu/white-search.svg"} alt="Recherche"/>
                   </Link>
                   <Link to={navigations.CORPORATE_PROCESS} className={`sidebar-btn ${getNavLinkClass(navigations.CORPORATE_PROCESS)}`}>
                       <img className="sidebar-btn-image" src={getNavLinkClass(navigations.CORPORATE_PROCESS)===""?"/assets/images/menu/blue-file.svg":"/assets/images/menu/white-file.svg"} alt="Presse-papier"/>
                   </Link>
                   <div className="sidebar-grow"></div>
                   <Link to={navigations.SETTINGS} className={`sidebar-btn ${getNavLinkClass(navigations.SETTINGS)}`}>
                       <img className="sidebar-btn-image" src={getNavLinkClass(navigations.SETTINGS)===""?"/assets/images/menu/blue-settings.svg":"/assets/images/menu/white-settings.svg"} alt="Paramètres"/>
                   </Link>
                   <button id="settings" className="sidebar-btn">
                       <div className="transition-container">
                           <img className="sidebar-btn-image" src="/assets/images/menu/blue-theme.svg" alt="Mode éclairci"/>
                       </div>
                   </button>
                   {((token!==undefined && token!==null && token!=="") && (chat_id!==undefined && chat_id!==null && chat_id!==""))?(<button onClick={()=>handleLogout()} className="sidebar-btn">
                       <img className="sidebar-btn-image" src="/assets/images/menu/logout.svg" alt="Deconnexion"/>
                   </button>):null}


               </div>
           </SidebarWrapper>
       </> );
};

export default Sidebar;
