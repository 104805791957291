import * as constants from "./constants";
export const initialState = {
    //TalkHistory
    talkHistoryLoading: false,
    talkHistory: [],
    talkHistoryError: null,
    //chatHistory
    chatHistoryLoading: false,
    chatHistory: [],
    chatHistoryError: null,

    //creditCount
    creditCountLoading: false,
    creditCount: null,
    creditCountError: null,

}
const chatReducer = (state = initialState, action)=> {
    switch (action.type) {

        case constants.TALK_HISTORY_REQUESTING:
            return {
                ...state,
                talkHistoryLoading:true
            };

        case constants.TALK_HISTORY_SUCCESS:
            return {
                ...state,
                talkHistoryLoading: false,
                talkHistory: action?.payload,
                talkHistoryError: null,
            };
        case constants.TALK_HISTORY_FAILED:
            return {
                ...state,
                talkHistoryLoading: false,
                talkHistory: [],
                talkHistoryError: action.error,
            };

        case constants.CHAT_HISTORY_REQUESTING:
            return {
                ...state,
                chatHistoryLoading:true
            };
        case constants.CHAT_HISTORY_SUCCESS:
            return {
                ...state,
                chatHistoryLoading: false,
                chatHistory: action?.payload,
                chatHistoryError: null,
            };
        case constants.CHAT_HISTORY_FAILED:
            return {
                ...state,
                chatHistoryLoading: false,
                chatHistory: [],
                chatHistoryError: action.error,
            };

        case constants.CREDIT_COUNT_REQUESTING:
            return {
                ...state,
                creditCountLoading:true
            };
        case constants.CREDIT_COUNT_SUCCESS:
            return {
                ...state,
                creditCountLoading: false,
                creditCount: action?.payload,
                creditCountError: null,
            };
        case constants.CREDIT_COUNT_FAILED:
            return {
                ...state,
                creditCountLoading: false,
                creditCount: null,
                creditCountError: action.error,
            };
        default:
            return state;
    }
}

export default chatReducer
