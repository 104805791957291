export const CHAT_HISTORY_REQUESTING = 'CHAT_HISTORY_REQUESTING'
export const CHAT_HISTORY_SUCCESS = 'CHAT_HISTORY_SUCCESS'
export const CHAT_HISTORY_FAILED = 'CHAT_HISTORY_FAILED'

export const TALK_HISTORY_REQUESTING = 'TALK_HISTORY_REQUESTING'
export const TALK_HISTORY_SUCCESS = 'TALK_HISTORY_SUCCESS'
export const TALK_HISTORY_FAILED = 'TALK_HISTORY_FAILED'

export const CREDIT_COUNT_REQUESTING = 'CREDIT_COUNT_REQUESTING'
export const CREDIT_COUNT_SUCCESS = 'CREDIT_COUNT_SUCCESS'
export const CREDIT_COUNT_FAILED = 'CREDIT_COUNT_FAILED'