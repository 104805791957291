import {combineReducers} from "redux";
import menuReducer from "./menu/reducer";
import modalReducer from "./modal/reducer";
import chatReducer from "./chat/reducer";
export const globalReducers = combineReducers({
    menu:menuReducer,
    modal:modalReducer,
    chat:chatReducer
});

