
export const payByCinetPay = (amount,customer_phone_number,customer_name,customer_surname,customer_email,customer_country='CI',customer_state='CI',returnMessage,history,lead_data,dispatch,description='paiement',customer_zip_code='06510',customer_address="BP 0024",customer_city='Abidjan', currency='XOF')=> {

/*    // @ts-ignore
    CinetPay.setConfig({
        apikey: process.env.REACT_APP_API_CINETPAY_KEY,
        site_id: process.env.REACT_APP_API_CINETPAY_SITE_ID,
        notify_url: '',
        mode: process.env.REACT_APP_API_CINETPAY_MODE
    });

    // @ts-ignore
    CinetPay.getCheckout({
        transaction_id: Math.floor(Math.random() * 100000000).toString(),
        amount: amount,
        currency: currency,
        channels: 'ALL',
        description: description,
        //Fournir ces variables pour le paiements par carte bancaire
        customer_name:customer_name,//Le nom du client
        customer_surname:customer_surname,//Le prenom du client
        customer_email: customer_email,//l'email du client
        customer_phone_number: customer_phone_number,//l'email du client
        customer_address : customer_address,//addresse du client
        customer_city: customer_city,// La ville du client
        customer_country : customer_country,// le code ISO du pays
        customer_state : customer_state,// le code ISO l'état
        customer_zip_code : customer_zip_code, // code postal
    });

    // @ts-ignore
    CinetPay.waitResponse(function(data) {
        if (data.status === "REFUSED") {
            // @ts-ignore
            if (alert("Votre paiement a échoué")) {
                window.location.reload();
            }

        } else if (data.status === "ACCEPTED") {
            dispatch(legafrikPaymentRequest(data,returnMessage,false,history))
            localStorage.clear();
            redirectTo(returnMessage,history)
        }

    });

    // @ts-ignore
    CinetPay.onError(function(data) {
        console.log(data);
    });*/

}

export const scrollToBottom=()=>{
    let element = document.getElementById('chatContainer');
    if(element!==undefined && element!==null ){
        setTimeout(() =>{
            element.scrollTo(0, element.scrollHeight);
        },100)
    }

}