import styled from "styled-components";

export const LayoutParent = styled.div`
  height:100vh;
 // background:#edf2f5;
  background: ${props =>props.background};
  
  
/*  background-image: url(https://app.customgpt.ai/storage/chat_bot_bg/lrNSfQDzYYquSMPwiiBz8aNuFMeOW4ROVGrc7wwi.jpg);
  --48ba4fed: #1D91BD;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: cover !important;
  overflow: hidden !important;*/
  
  
  transition-property: all;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  transition-duration: .15s;
  overflow-y: auto;
  @media all and (max-width: 975px) {
    width:inherit;
  }
  `;
export const LayoutWrapper = styled.div`
 // padding-top: 40px !important;
  margin-left: 5rem;
  padding:10px;
  @media all and (max-width: 975px) {
    margin-left: 0;
  }
  `;
