import * as constants from "./constants";
export const initialState = {
    isMenuOpen:false
}

const menuReducer = (state = initialState, action)=> {
    switch (action.type) {
        case constants.TOGGLE_MENU:
            return {
                ...state,
                isMenuOpen:!state.isMenuOpen,
            };
        default:
            return state;
    }
}
export default menuReducer