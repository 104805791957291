export default function authHeader() {
    const token = localStorage.getItem('token');
    if (token) {
        return {
            "Content-Type": "application/json",
            'Authorization': token ? `Bearer ${token}` : ""};
    } else {
        return {};
    }
}
