import {createStore,applyMiddleware,compose} from 'redux';
import logger from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import rootSagas from "./sagas";
import {globalReducers} from "./reducers";

const configureStore = () => {
    const sagaMiddleware = createSagaMiddleware()
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const enhancer = composeEnhancers(applyMiddleware(sagaMiddleware,logger));
    const store = createStore(globalReducers, enhancer);
    // then run the saga
    sagaMiddleware.run(rootSagas);
    return {store};
};
const {store} = configureStore(); // exported this instead;

export {store};