import {BrowserRouter} from "react-router-dom";
import LfRoutes from "./routes";
import {Provider} from "react-redux";
import {store} from "./store/store";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { LightTheme, BaseProvider } from 'baseui';
const engine = new Styletron();

function App() {
  return (
      <Provider store={store}>
          <StyletronProvider value={engine}>
              <BaseProvider theme={LightTheme}>
                  <BrowserRouter>
                      <LfRoutes/>
                  </BrowserRouter>
                  <ToastContainer />
              </BaseProvider>
          </StyletronProvider>
      </Provider>
  );
}

export default App;
