import React, { memo } from "react";
import {Container, Dots} from "./InlineLoader.style";

const Dot = (delay) => {
    // @ts-ignore
    return <Dots delay={delay} />;
};

export const InLineLoader= memo((props) => {
    const { color = "#006699" } = props;

    return (
        <Container color={color}>
            <Dot delay=".8s" />
            <Dot delay=".7s" />
            <Dot delay=".6s" />
            <Dot delay=".5s" />
            <Dot delay=".4s" />
            <Dot delay=".3s" />
            <Dot delay=".2s" />
            <Dot delay=".1s" />
        </Container>
    );
});
