import * as constants from "./constants";

export const  talkHistoryRequest =(chat_id)=> {
    return { type: constants.TALK_HISTORY_REQUESTING,chat_id}
}

export const talkHistorySuccess=(payload)=>{
    return { type:constants.TALK_HISTORY_SUCCESS, payload }
}

export const talkHistoryFailed=(error)=>{
    return { type:constants.TALK_HISTORY_FAILED, error }
}

//chat
export const  chatHistoryRequest =()=> {
    return { type: constants.CHAT_HISTORY_REQUESTING}
}

export const chatHistorySuccess=(payload)=>{
    return { type:constants.CHAT_HISTORY_SUCCESS, payload }
}

export const chatHistoryFailed=(error)=>{
    return { type:constants.CHAT_HISTORY_FAILED, error }
}

//credit count
export const  creditCountRequest =()=> {
    return { type: constants.CREDIT_COUNT_REQUESTING}
}

export const creditCountSuccess=(payload)=>{
    return { type:constants.CREDIT_COUNT_SUCCESS, payload }
}

export const creditCountFailed=(error)=>{
    return { type:constants.CREDIT_COUNT_FAILED, error }
}

