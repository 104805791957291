import {all} from 'redux-saga/effects';
import chatSaga from "./chat/saga";

/**
 *
 */
export default function* rootSagas() {
    yield all([
        chatSaga()
    ]);
}
