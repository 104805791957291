import React from 'react';
import SideBar from "./SideBar/SideBar";
import {LayoutParent, LayoutWrapper} from "./Layout.style";
import MenuBackdrop from "../components/menuBackdrop/menuBackdrop";
import ResponsiveBtn from "../components/responseBtn/responseBtn";
import {useDispatch, useSelector} from "react-redux";
import {menuSelector} from "../store/menu/selectors";
import {toggleMenu} from "../store/menu/actions";
import {useLocation} from "react-router-dom";
const Layout =({children})=>{
    let location = useLocation();

    const dispatch = useDispatch();
    const useMenu = menuSelector(
        useSelector(state => state),
    );
    const handleClick = () => {
        dispatch(toggleMenu());
    };
    if (location.pathname==="/"){
        console.log("Chatter : ");
    }else{
        console.log("Non pas un chat : ",location.pathname);
    }
    return (<>
        <MenuBackdrop isMenuOpen={useMenu?.isMenuOpen} onClick={handleClick}/>
        <LayoutParent background={location?.pathname==="/"?'#edf2f5':"#edf2f5"}>
            <SideBar isMenuOpen={useMenu?.isMenuOpen}/>
            <ResponsiveBtn onClick={()=>dispatch(toggleMenu())}/>
            <LayoutWrapper>
                {children}
            </LayoutWrapper>
        </LayoutParent>
    </>

    )
}

export default Layout
