import {put, call, takeEvery} from 'redux-saga/effects';
import * as Actions from './actions';
import * as _service from './service';
import * as Constants from './constants';
import {scrollToBottom} from "../../utils/utils";
import {creditCountRequest} from "./actions";
import {fetchCreditCount} from "./service";


function* loadTalkHistorySaga(payload) {
  const {chat_id} = payload;
  try {
    const response = yield call(_service.fetchTalkHistory, chat_id);
    if (response.status === 200) {
      yield put(Actions.talkHistorySuccess(response?.data?.talks));
        scrollToBottom();
    } else {
      yield put(Actions.talkHistoryFailed(response.error));
    }
  } catch (e) {
     yield put(Actions.talkHistoryFailed(e));
  }
}

function* loadChatHistorySaga() {
  try {
    const response = yield call(_service.fetchChatHistory);
    if (response.status === 200) {
      yield put(Actions.chatHistorySuccess(response?.data));
       scrollToBottom();
    } else {
      yield put(Actions.chatHistoryFailed(response.error));
    }
  } catch (e) {
    yield put(Actions.chatHistoryFailed(e));
  }
}

function* loadCreditCountSaga() {
  try {
    const response = yield call(_service.fetchCreditCount);
    if (response.status === 200) {
      yield put(Actions.creditCountSuccess(response?.data?.premium));
    } else {
      yield put(Actions.creditCountFailed(response.error));
    }
  } catch (e) {
    yield put(Actions.creditCountFailed(e));
  }
}

export default function* chatSaga() {
  yield takeEvery(
    Constants.TALK_HISTORY_REQUESTING,
      loadTalkHistorySaga,
  );
  yield takeEvery(
      Constants.CHAT_HISTORY_REQUESTING,
      loadChatHistorySaga,
  );
  yield takeEvery(
      Constants.CREDIT_COUNT_REQUESTING,
      loadCreditCountSaga,
  );

}
